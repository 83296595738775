import React from 'react';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import example_gantt_chart from '../../../../../../../common/src/assets/image/example_gantt_chart.png';
import template_options_gantt_chart from '../../../../../../../common/src/assets/image/template_options_gantt_chart.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        The Gantt Chart template style generates a calendar that is formatted as
        a{' '}
        <a
          href="http://en.wikipedia.org/wiki/Gantt_chart"
          target="_blank"
          rel="noopener noreferrer"
        >
          Gantt chart
        </a>
        . Each task will be ordered based on when it is schedule to start, with
        a timeline showing the task duration and due date. Tasks that don't have
        a duration will be indicated using a diamond shape.
      </p>
      <p>
        <Image alt="" src={example_gantt_chart} />
      </p>
      <h2>Template Settings</h2>
      <p>
        The Gantt Chart template provides the following options when creating a
        calendar...
      </p>
      <Image alt="" src={template_options_gantt_chart} style={{ width: 448 }} />
      <br />
      <table className="table">
        <tbody>
          <tr>
            <td>
              <strong>Date range</strong>
            </td>
            <td>
              The start and end range to be shown in the Gantt chart. Dates can
              be typed in or selected by clicking the drop-down arrow.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Include weekends when calculating task durations</strong>
            </td>
            <td>
              When checked, weekends will be included in the calculated duration
              values that are shown in the <strong>Duration</strong> column.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Templates-and-Options/Gantt-Chart"
      commentsId="commentsplus_post_132_489"
      title="Gantt Chart Template"
      description="The Gantt Chart template style generates a printable calendar that is formatted as a Gantt chart. Each task will be ordered based on when it is schedule to start."
      keywords="printable Gantt Chart, generate Gantt Chart, create Gantt Chart, Excel Gantt Chart, Word Gantt Chart, printable tasks, printable calendar, print tasks, Gantt Chart template"
      content={content}
    />
  );
};

export default Documentation;
